/**
 *
 * @param {{
 * 	category: {
 * 		slug: string
 * 	},
 * 	parentCategory: {
 * 		slug: string
 * 	},
 * 	parentPlace: {
 * 		slug: string
 * 	},
 * 	place: {
 * 		slug: string
 * 	},
 * 	googlePlace: {
 * 		place_id: string
 * 	}
 * }} search
 * @param {string[]} slugs
 * @returns {boolean}
 */
export const isValidUrlOrder = (search = {}, slugs = []) => {
	const parentCategory = search.parentCategory?.slug;
	const category = search.category?.slug;
	const parentPlace = search.parentPlace?.slug;
	const place = search.place?.slug;
	const googlePlace = search.googlePlace?.place_id
		? `gp_${search.googlePlace.place_id}`
		: null;

	const validPatterns = [
		// /parentCategory
		{
			length: 1,
			validate: segs => segs[0] === parentCategory
		},
		// /parentCategory/category
		{
			length: 2,
			validate: segs => segs[0] === parentCategory && segs[1] === category
		},
		// /parentCategory/parentPlace
		{
			length: 2,
			validate: segs => segs[0] === parentCategory && segs[1] === parentPlace
		},
		// /parentCategory/googlePlace
		{
			length: 2,
			validate: segs => segs[0] === parentCategory && segs[1] === googlePlace
		},
		// /parentCategory/category/googlePlace
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === googlePlace
		},
		// /parentCategory/category/parentPlace
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === parentPlace
		},
		// /parentCategory/parentPlace/place
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === parentPlace &&
				segs[2] === place
		},
		// /parentCategory/category/parentPlace/place
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === parentPlace &&
				segs[3] === place
		}
	].filter(pattern => pattern.length === slugs.length);

	return validPatterns.some(pattern => pattern.validate(slugs));
};

/**
 *
 * @param {string[]} slugs
 * @param {{
 * 	category: {
 * 		slug: string
 * 	},
 * 	parentCategory: {
 * 		slug: string
 * 	},
 * 	parentPlace: {
 * 		slug: string
 * 	},
 * 	place: {
 * 		slug: string
 * 	},
 * 	googlePlace: {
 * 		place_id: string
 * 	}
 * }} search
 * @param {{
 *	slug: string
 * }} category
 * @returns {boolean}
 */
export const matchesAllowedPattern = (slugs = [], search = {}, category) => {
	if (category && slugs.length === 1) {
		return category?.slug === slugs[0];
	}

	if (!search) return false;

	const isValidSlug = slugs.every(
		slug =>
			slug === search.category?.slug ||
			slug === search.parentCategory?.slug ||
			slug === search.parentPlace?.slug ||
			slug === search.place?.slug ||
			slug === `gp_${search.googlePlace?.place_id}`
	);
	if (!isValidSlug) return false;

	return isValidUrlOrder(search, slugs);
};
