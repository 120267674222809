// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title-module_title-5XjLY{font:var(--medium) var(--body-1);margin-bottom:8px}@media(min-width: 768px){.title-module_title-5XjLY{margin-top:32px;cursor:default;display:flex}}.title-module_title-5XjLY .title-module_index-V5qhP{margin-right:4px;color:var(--primary-200)}.title-module_title-5XjLY.title-module_isDark-O7x1G:nth-child(1){color:var(--white) !important}.title-module_business-services-8\\+YRg{display:block;margin-top:0;font:var(--medium) var(--body-1);margin-bottom:12px}@media(min-width: 768px){.title-module_business-services-8\\+YRg{margin-bottom:-8px;font:var(--semibold) var(--title-3)}}", "",{"version":3,"sources":["webpack://./../../packages/components/book_appointment/title.module.scss"],"names":[],"mappings":"AAEA,0BAEC,gCAAA,CACA,iBAAA,CACA,yBAJD,0BAKE,eAAA,CACA,cAAA,CACA,YAAA,CAAA,CAGD,oDACC,gBAAA,CACA,wBAAA,CAED,iEACC,6BAAA,CAMF,uCACC,aAAA,CACA,YAAA,CACA,gCAAA,CACA,kBAAA,CACA,yBALD,uCAME,kBAAA,CACA,mCAAA,CAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables.scss';\n\n.title {\n\t//margin-top: 44px;\n\tfont: var(--medium) var(--body-1);\n\tmargin-bottom: 8px;\n\t@media (min-width: variables.$tablet) {\n\t\tmargin-top: 32px;\n\t\tcursor: default;\n\t\tdisplay: flex;\n\t}\n\n\t.index {\n\t\tmargin-right: 4px;\n\t\tcolor: var(--primary-200);\n\t}\n\t&.isDark:nth-child(1) {\n\t\tcolor: var(--white) !important;\n\t}\n}\n\n// That style is not good, it shouldn't be here.\n// Devs have mismatch style and style available for white label\n.business-services {\n\tdisplay: block;\n\tmargin-top: 0;\n\tfont: var(--medium) var(--body-1);\n\tmargin-bottom: 12px;\n\t@media (min-width: variables.$tablet) {\n\t\tmargin-bottom: -8px;\n\t\tfont: var(--semibold) var(--title-3);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"title": "title-module_title-5XjLY",
	"index": "title-module_index-V5qhP",
	"isDark": "title-module_isDark-O7x1G",
	"business-services": "title-module_business-services-8+YRg"
};
module.exports = ___CSS_LOADER_EXPORT___;
