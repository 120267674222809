import classNames from 'classnames/bind';
import styles from './button.module.scss';
import { Icon, Spinner } from '@planity/ui';
import { Link } from 'react-router-dom';
import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { noop } from '@planity/helpers';
import { useHistory } from 'react-router-dom';

export function Button({
	type = 'primary',
	label = '',
	noFocus,
	size = 'medium',
	isFullWidth,
	isFullMobile,
	isLoading,
	isDisabled,
	hasColoredIcon,
	hasBadgeIcon,
	iconLeft,
	iconRight,
	isExternal,
	className,
	to,
	onClick = noop,
	id,
	outlined,
	strokeWidth,
	stroke,
	hasAvailabilities,
	hasOtherCategories,
	onBlur = noop,
	normalWhiteSpace = false,
	loadingLabel,
	leftIconClass,
	rightIconClass,
	useEllipsis,
	isDark,
	obfuscateLink = false,
	...otherProps
}) {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const classes = cx({
		button: true,
		[size]: true,
		[type]: true,
		[className]: className !== undefined,
		isLoading,
		isDisabled,
		isFullWidth,
		hasColoredIcon,
		hasBadgeIcon,
		noFocus,
		hasAvailabilities,
		hasOtherCategories,
		isFullMobile,
		normalWhiteSpace,
		isDark,
		ellipsis: useEllipsis || false
	});
	const history = useHistory();

	const renderLoading = (
		<>
			<span className={cx({ loadingLabel: !!loadingLabel })}>
				{loadingLabel}
			</span>
			<Spinner />
		</>
	);

	// all icons in buttons are in medium size
	const renderText = (
		<>
			<Icon
				className={cx(
					{ iconLeft: !!iconLeft, isAlone: !label && !iconRight },
					leftIconClass
				)}
				icon={iconLeft}
				outlined={outlined}
				strokeWidth={strokeWidth}
				stroke={stroke}
				size={'medium'}
			/>
			<span className={styles.label}>{label}</span>
			<Icon
				className={cx(
					{ iconRight: !!iconRight, isAlone: !label && !iconLeft },
					rightIconClass
				)}
				icon={iconRight}
				outlined={outlined}
				strokeWidth={strokeWidth}
				stroke={stroke}
				size={'medium'}
			/>
		</>
	);

	const dataProps = Object.entries(otherProps).reduce(
		(all, [propName, propValue]) => {
			if (propName.match(/^data-/)) {
				all[propName] = propValue;
			}
			return all;
		},
		{}
	);

	if (isExternal) {
		if (obfuscateLink) {
			return (
				<button
					id={id}
					className={classes}
					aria-label={isLoading && label}
					onClick={() => window.open(to, '_blank')}
					{...dataProps}
				>
					{isLoading ? renderLoading : renderText}
				</button>
			);
		}
		return (
			<a
				id={id}
				href={to}
				className={classes}
				aria-label={isLoading && label}
				rel='noopener noreferrer'
				target='_blank'
				onClick={onClick}
				{...dataProps}
			>
				{isLoading ? renderLoading : renderText}
			</a>
		);
	}

	if (to) {
		if (obfuscateLink) {
			return (
				<button
					id={id}
					disabled={isDisabled || isLoading}
					className={classes}
					onClick={() => history.push(to)}
					aria-label={isLoading && label}
					onMouseDown={e => e.preventDefault()} // Prevent focus state on click
					{...dataProps}
				>
					{isLoading ? renderLoading : renderText}
				</button>
			);
		}
		return (
			<Link
				to={to}
				id={id}
				disabled={isDisabled || isLoading}
				className={classes}
				onClick={onClick}
				aria-label={isLoading && label}
				onMouseDown={e => e.preventDefault()} // Prevent focus state on click
				{...dataProps}
			>
				{isLoading ? renderLoading : renderText}
			</Link>
		);
	}
	return (
		<button
			id={id}
			disabled={isDisabled || isLoading}
			className={classes}
			aria-label={isLoading && label}
			onClick={e => {
				e.currentTarget.blur();
				onClick(e);
			}}
			onBlur={onBlur}
			{...dataProps}
		>
			{isLoading ? renderLoading : renderText}
			{!!hasBadgeIcon && (
				<span className={cx({ badgeIcon: true })}>{hasBadgeIcon}</span>
			)}
		</button>
	);
}
